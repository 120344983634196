import React from 'react';

import AppContainer from 'components/landing/AppContainer';
import Tree from 'components/landing/Tree';
import Intro from 'components/landing/Intro';
import WhatWeDo from 'components/landing/WhatWeDo';
import Slides from 'components/landing/Slides';
import SelectedClients from 'components/landing/SelectedClients';
import Quote from 'components/landing/Quote';
import ImpactCards from 'components/landing/ImpactCards';
import OutrunGrid from 'components/landing/OutrunGrid';

const LandingPage: React.FC = () => {
  return (
    <AppContainer showFooter={false} pad={false}>
      <Tree />
      <Intro />
      <WhatWeDo />
      <Slides />
      <SelectedClients />
      <Quote />
      <ImpactCards />
      <OutrunGrid />
    </AppContainer>
  );
};

export default LandingPage;
