import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Mesh } from 'three';
import { Canvas, useFrame } from '@react-three/fiber';
import { type SpringValue, useSpring } from '@react-spring/web';
import { animated, Globals } from '@react-spring/three';
import { useGLTF, useProgress } from '@react-three/drei';
import * as easing from 'maath/easing';
import * as THREE from 'three';
import { useElementScrollPercentage } from 'hooks/useElementScrollPercentage';

Globals.assign({
  frameLoop: 'always'
});

const Content = styled.div`
  display: grid;
  height: 100lvh;
  position: sticky;
  width: 100%;
  align-content: stretch;
  justify-content: stretch;
  top: 0;
`;

const Container = styled.div<{ $debug?: boolean }>`
  height: 300lvh;
  width: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  ${({ $debug }) =>
    $debug &&
    css`
      border: dotted blue 4px;

      ${Content} {
        border: dotted green 4px;
      }
    `}
`;

interface SceneProps {
  progress: { value: SpringValue<number> };
}

const Scene: React.FC<SceneProps> = ({ progress }) => {
  const gltf = useGLTF('/objects/tree.glb');
  const ref = useRef<Mesh>();

  useFrame((state, dt) => {
    // state.camera.lookAt(0, 0, 0);
    const y = progress.value.to([0, 1], [28.3, 5]).get();
    easing.damp3(state.camera.position, [1.332, y, 10.167], 0.25, dt);

    if (ref.current) {
      const r = progress.value.to([0, 1], [0.2, 0.7]).get();
      easing.dampE(ref.current.rotation, [0, Math.PI * r, 0], 0.25, dt);

      // spin the disco ball
      gltf.scenes[0].children[2].rotation.y += 0.001;
    }
  });

  useEffect(() => {
    const material = new THREE.MeshStandardMaterial({
      color: 0xffffff, // set the color of the material
      roughness: 0, // set the roughness of the material to 0 for a shiny look
      metalness: 0.75 // set the metalness of the material to 1 for a metallic look
    });

    (gltf.scenes[0].children[2] as THREE.Mesh).material = material;
  }, [gltf.scenes]);

  return <primitive object={gltf.scene} ref={ref} rotation={[0, 0.6283185307179586, 0]} />;
};

const TreeSceneContainer: React.FC<{
  scrollPercentage: { value: SpringValue<number> };
  play: boolean;
}> = ({ scrollPercentage, play }) => {
  const loadProgress = useProgress();
  const lightValue = useSpring({ from: { value: 0 } });

  useEffect(() => {
    if (loadProgress.progress === 100) {
      lightValue.value.start(1);
    }
  }, [loadProgress, lightValue]);

  return (
    <>
      <Canvas
        frameloop={play ? 'always' : 'never'}
        camera={{
          position: [1.332, 28.3, 10.167],
          rotation: [-0.393, -0.087, -0.032]
        }}
      >
        <animated.pointLight
          intensity={lightValue.value.to(val => 230 * val)}
          position={[0, 30, 30]}
        />
        <animated.pointLight
          intensity={lightValue.value.to(val => 1500 * val)}
          position={[-10, 0, -30]}
        />
        <Scene progress={scrollPercentage} />
      </Canvas>
    </>
  );
};

interface TreeProps {
  debug?: boolean;
}

const Tree: React.FC<TreeProps> = ({ debug }) => {
  const { ref, scrollPercentage, onScreen } = useElementScrollPercentage({
    startAtTop: true
  });

  return (
    <Container ref={ref} $debug={debug}>
      <Content>
        {!debug && <TreeSceneContainer scrollPercentage={scrollPercentage} play={onScreen} />}
      </Content>
    </Container>
  );
};

export default Tree;
