import React, { useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styled, { css } from 'styled-components';
import { useElementScrollPercentage } from 'hooks/useElementScrollPercentage';
import useIsVisible from 'hooks/useIsVisible';
import { animated } from '@react-spring/web';
import CTAButton from 'components/landing/CTAButton';

type VideoAsset = {
  src: string;
  type: 'video' | 'image';
  alt: string;
};

const track1 = [
  { src: '/videos/thumbnail-01.mp4', type: 'video', alt: 'Lapto in the bushes' },
  {
    src: '/images/thumbnail-02.jpg',
    type: 'image',
    alt: 'View from the fire escape of the Chicago office'
  },
  { src: '/videos/thumbnail-03.mp4', type: 'video', alt: 'Goodfriend on the floor' },
  { src: '/images/thumbnail-04.jpg', type: 'image', alt: 'Refract black prism' },
  { src: '/images/thumbnail-05.jpg', type: 'image', alt: 'Silhouette doing important science' }
] as VideoAsset[];

const track2 = [
  { src: '/images/thumbnail-06.jpg', type: 'image', alt: 'Starface on a mobile device' },
  { src: '/videos/thumbnail-07.mp4', type: 'video', alt: 'Myopic books logo' },
  { src: '/images/thumbnail-08.jpg', type: 'image', alt: 'The old Chicago office' },
  { src: '/images/thumbnail-09.jpg', type: 'image', alt: '#Halfthestory on a mobile device' },
  { src: '/images/thumbnail-10.jpg', type: 'image', alt: 'Interior view of the Chicago office' }
] as VideoAsset[];

const track3 = [
  { src: '/images/thumbnail-11.jpg', type: 'image', alt: 'Our video production camera' },
  { src: '/videos/thumbnail-12.mp4', type: 'video', alt: 'Stacked cupbes' },
  { src: '/images/thumbnail-13.jpg', type: 'image', alt: 'Interior view of the Chicago office' },
  {
    src: '/images/thumbnail-14.jpg',
    type: 'image',
    alt: 'Laptop displaying a graphic we made for Plume'
  },
  {
    src: '/images/thumbnail-15.jpg',
    type: 'image',
    alt: 'Action shot of the Chicago conference room'
  }
] as VideoAsset[];

const Slide = styled.div`
  height: 20lvh;
  border-radius: 1rem;
  aspect-ratio: 9/6;
  position: relative;
  overflow: hidden;

  img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
`;

const SlidesContainer = styled(animated.section)<{ $debug?: boolean }>`
  display: grid;
  position: relative;
  gap: 0.5rem;
  overflow: hidden;
  max-width: 100vw;
  width: 100%;

  ${({ $debug }) =>
    $debug &&
    css`
      ${Slide} {
        border: dotted blanchedalmond 4px;
      }
    `}
`;

const SlideTrack = styled(animated.div)<{ $rtl?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  position: relative;
  gap: 0.5rem;
  right: calc(100% - 100vw);

  ${({ $rtl }) =>
    $rtl &&
    css`
      right: unset;
    `}

  ${({ $rtl, theme: { mq } }) => mq.md`
    ${
      $rtl &&
      css`
        right: unset;
      `
    }
  `}
`;

const SCTAButton = styled(CTAButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

const Video = ({ src, alt }: Partial<VideoAsset>) => {
  const { ref, isVisible } = useIsVisible();

  useEffect(() => {
    if (ref.current) {
      if (isVisible) {
        (ref.current as HTMLVideoElement).play();
      } else {
        (ref.current as HTMLVideoElement).pause();
      }
    }
  }, [isVisible, ref]);

  return (
    <video
      autoPlay
      muted
      loop
      controls={false}
      playsInline
      ref={ref as React.LegacyRef<HTMLVideoElement>}
    >
      <source src={src} type="video/mp4" />
      <p>{alt}</p>
    </video>
  );
};

interface SlidesProps {
  debug?: boolean;
}

const Slides: React.FC<SlidesProps> = ({ debug }) => {
  const { ref: slidesRef, scrollPercentage: slidesScrollPercentage } = useElementScrollPercentage({
    startAtTop: false,
    inertia: false
  });

  return (
    <SlidesContainer
      $debug={debug}
      ref={slidesRef}
      style={{
        opacity: slidesScrollPercentage.value.to([0, 0.3], [0, 1], 'clamp')
      }}
    >
      <SlideTrack
        style={{
          transform: slidesScrollPercentage.value.to(
            val => `translate3d(${val * 100 * 0.1}%, 0, 0)`
          )
        }}
      >
        {[...track1, ...track1].map((asset, i) => (
          <Slide key={`${asset.src}-${i}`}>
            {!debug &&
              (asset.type === 'video' ? (
                <Video src={asset.src} alt={asset.alt} />
              ) : (
                <Image priority src={asset.src} width={640} height={480} alt={asset.alt} />
              ))}
          </Slide>
        ))}
      </SlideTrack>
      <SlideTrack
        $rtl
        style={{
          transform: slidesScrollPercentage.value.to(
            val => `translate3d(-${val * 100 * 0.1}%, 0, 0)`
          )
        }}
      >
        {[...track2, ...track2].map((asset, i) => (
          <Slide key={`${asset.src}-${i}`}>
            {!debug &&
              (asset.type === 'video' ? (
                <Video src={asset.src} alt={asset.alt} />
              ) : (
                <Image priority src={asset.src} width={640} height={480} alt={asset.alt} />
              ))}
          </Slide>
        ))}
      </SlideTrack>
      <SlideTrack
        style={{
          transform: slidesScrollPercentage.value.to(
            val => `translate3d(${val * 100 * 0.1}%, 0, 0)`
          )
        }}
      >
        {[...track3, ...track3].map((asset, i) => (
          <Slide key={`${asset.src}-${i}`}>
            {!debug &&
              (asset.type === 'video' ? (
                <Video src={asset.src} alt={asset.alt} />
              ) : (
                <Image priority src={asset.src} width={640} height={480} alt={asset.alt} />
              ))}
          </Slide>
        ))}
      </SlideTrack>

      <Link href="/projects">
        <SCTAButton>See Our Work</SCTAButton>
      </Link>
    </SlidesContainer>
  );
};

export default Slides;
