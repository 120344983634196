import React, { useRef, useEffect, useCallback } from 'react';
import FadeInText, { Letter } from 'components/FadeInText';
import { useElementScrollPercentage } from 'hooks/useElementScrollPercentage';
import useIsVisible from 'hooks/useIsVisible';
import styled, { css } from 'styled-components';

const IntroTextContainer = styled.section<{ $debug?: boolean }>`
  position: relative;
  height: 200lvh;
  padding: 0 2rem;

  ${({ $debug }) =>
    $debug &&
    css`
      border: dotted red 2px;
    `}
`;

const MobileBr = styled.br`
  ${({ theme: { mq } }) => mq.md`
    display: none;
  `}
`;

const DesktopBr = styled.br`
  display: none;
  ${({ theme: { mq } }) => mq.md`
    display: unset;
  `}
`;

const IntroTextRevealProgress = styled.section`
  position: absolute;
  height: 80lvh;
`;

const VideoPill = styled.div<{ $debug?: boolean }>`
  height: 8vw;
  border-radius: 16rem;
  display: inline-block;
  position: relative;
  overflow: hidden;
  aspect-ratio: 1.7/1;
  background: rgba(255, 255, 255, 0.5);
  position: relative;
  top: 0.7vw;

  ${({ theme: { mq } }) => mq.md`
    height: clamp(4rem, 7vw, 8rem);
  `}

  ${({ $debug }) =>
    $debug &&
    css`
      border: dotted pink 4px;
    `}
`;

const IntroText = styled.div<{ $debug?: boolean }>`
  position: sticky;
  top: 0;
  width: 100%;
  height: 100lvh;
  display: grid;
  align-items: center;
  justify-content: center;
  font-family: SpaceGroteskLight;
  font-weight: 300;
  font-size: 10vw;
  min-width: 100%;
  line-height: 1.05;

  ${({ $debug }) =>
    $debug &&
    css`
      border: dotted blanchedalmond 4px;
    `}

  ${Letter} {
    ${VideoPill} {
      opacity: 1 !important;
    }
  }

  ${({ theme: { mq } }) => mq.md`
    text-align: center;
    font-size: clamp(4rem, 7vw, 8rem);
  `}
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.2s;

  &.active {
    opacity: 1;
  }
`;

const VPill: React.FC<{ src: string; poster: string; debug?: boolean }> = ({
  src,
  poster,
  debug,
  ...props
}) => {
  const { ref } = useIsVisible();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const updateVisibility = useCallback(() => {
    const opacity = parseFloat(ref.current?.parentElement?.style?.opacity || '0.2');
    if (opacity > 0.2) {
      videoRef.current?.classList.add('active');
    } else {
      videoRef.current?.classList.remove('active');
    }
  }, [ref]);

  useEffect(() => {
    const config = { attributes: true, attributeFilter: ['style'] };

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          updateVisibility();
        }
      });
    });

    if (ref.current?.parentElement) {
      observer.observe(ref.current.parentElement, config);
      updateVisibility();
    }
    return () => {
      observer.disconnect();
    };
  }, [ref, updateVisibility]);

  return (
    <VideoPill $debug={debug} ref={ref as React.MutableRefObject<HTMLDivElement>} {...props}>
      {!debug && (
        <Video autoPlay muted loop controls={false} playsInline poster={poster} ref={videoRef}>
          <source src={src} type="video/mp4" />
        </Video>
      )}
    </VideoPill>
  );
};

interface IntroProps {
  debug?: boolean;
}

const Intro: React.FC<IntroProps> = ({ debug }) => {
  const { ref: introRef, scrollPercentage: introScrollPercentage } = useElementScrollPercentage({
    startAtTop: true,
    inertia: true,
    springConfig: {
      tension: 0
    }
  });

  return (
    <IntroTextContainer $debug={debug}>
      <IntroTextRevealProgress ref={introRef} />
      <IntroText $debug={debug}>
        <FadeInText progress={introScrollPercentage}>
          <span>Graveflex</span>
          <MobileBr />{' '}
          <VPill
            debug={debug}
            src="/videos/video-blob-1.mp4"
            poster="/images/blob-1-poster.png"
          />{' '}
          is a <MobileBr />
          <DesktopBr />
          creative{' '}
          <VPill
            debug={debug}
            src="/videos/video-blob-2.mp4"
            poster="/images/blob-2-poster.png"
          />{' '}
          technology
          <MobileBr />
          <DesktopBr /> agency
          <MobileBr /> in{' '}
          <VPill debug={debug} src="/videos/video-blob-3.mp4" poster="/images/blob-3-poster.png" />
          <MobileBr /> Chicago
        </FadeInText>
      </IntroText>
    </IntroTextContainer>
  );
};

export default Intro;
