import dynamic from 'next/dynamic';

const defaultOpts = {
  suspense: true,
  ssr: true
};

export const ChipBlock = dynamic(() => import('./ChipBlock'), { ...defaultOpts });
export const ProjectListBlock = dynamic(() => import('./ProjectListBlock'), { ...defaultOpts });
export const KitchenSink = dynamic(() => import('./KitchenSink'), { ...defaultOpts });
export const PageHero = dynamic(() => import('./PageHero'), { ...defaultOpts });
export const FullWidthImage = dynamic(() => import('./FullWidthImage'), { ...defaultOpts });
export const TextBlock = dynamic(() => import('./TextBlock'), { ...defaultOpts });
export const ThemeSliderBlock = dynamic(() => import('./ThemeSliderBlock'), { ...defaultOpts });
export const ResultsBlock = dynamic(() => import('./ResultsBlock'), { ...defaultOpts });
export const SpacerBlock = dynamic(() => import('./SpacerBlock'), {
  ...defaultOpts
});
export const ProgressCirclesBlock = dynamic(() => import('./ProgressCirclesBlock'), {
  ...defaultOpts
});
export const QuoteBlock = dynamic(() => import('./QuoteBlock'), { ...defaultOpts });
export const ThreeColumnImageAndTextBlock = dynamic(
  () => import('./ThreeColumnImageAndTextBlock'),
  { ...defaultOpts }
);
export const ProcessBlock = dynamic(() => import('./ProcessBlock'), { ...defaultOpts });
export const HorizontalSlideshow = dynamic(() => import('./HorizontalSlideshow'), {
  ...defaultOpts
});
export const ProjectHero = dynamic(() => import('./ProjectHero'), { ...defaultOpts });
export const AnimatedMap = dynamic(() => import('./AnimatedMap'), { ...defaultOpts });
export const ContactForm = dynamic(() => import('./ContactForm'), { ...defaultOpts });
export const TextImageBlock = dynamic(() => import('./TextImageBlock'), { ...defaultOpts });
export const VideoBlock = dynamic(() => import('./VideoBlock'), { ...defaultOpts });
export const BlendedText = dynamic(() => import('./BlendedText'), { ...defaultOpts });
export const BlendedContactBlock = dynamic(() => import('./BlendedContactBlock'), {
  ...defaultOpts
});
export const AnimatedColumnBlock = dynamic(() => import('./AnimatedColumnBlock'), {
  ...defaultOpts
});
export const TextWithColumnsBlock = dynamic(() => import('./TextWithColumnsBlock'), {
  ...defaultOpts
});
export const CGRRiverBlock = dynamic(() => import('./CGRRiverBlock'), { ...defaultOpts });
export const ContainedImage = dynamic(() => import('./ContainedImage'), {
  ...defaultOpts
});
export const Landing = dynamic(() => import('./Landing'), { ...defaultOpts });
export const AboutHero = dynamic(() => import('./AboutHero'), { ...defaultOpts });
export const AboutItem = dynamic(() => import('./AboutItem'), { ...defaultOpts });
export const TextAndIcons = dynamic(() => import('./TextAndIcons'), { ...defaultOpts });
export const QuoteShowcase = dynamic(() => import('./QuoteShowcase'), { ...defaultOpts });
export const Team = dynamic(() => import('./Team'), { ...defaultOpts });
export const ScrollableImage = dynamic(() => import('./ScrollableImage'), { ...defaultOpts });
export const BackgroundImage = dynamic(() => import('./BackgroundImage'), { ...defaultOpts });
export const ProjectsIndex = dynamic(() => import('./ProjectsIndex'), { ...defaultOpts });
export const QuoteWithAuthor = dynamic(() => import('./QuoteWithAuthor'), { ...defaultOpts });
export const NumbersBlock = dynamic(() => import('./NumbersBlock'), { ...defaultOpts });
export const ToolsBlock = dynamic(() => import('./ToolsBlock'), { ...defaultOpts });
