import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSpring, type SpringValue, type SpringConfig } from '@react-spring/web';

interface UseScrollPercentageProps {
  startAtTop?: boolean;
  inertia?: boolean;
  springConfig?: SpringConfig;
}

// styled components is using an outdated ref type
type ScRef =
  | ((instance: HTMLElement | null) => void)
  | React.RefObject<HTMLDivElement>
  | null
  | undefined;

// returns a value between 0 and 1 that represents the amount of an element that has been scrolled
// through. when the `startAtTop` option is `true`, the percentage will start when the element has
// reached the top of the page. otherwise the percentage starts when the element scrolls in from the
// bottom of the page. the percentage will reach 1 when the page has scrolled past the element
export const useElementScrollPercentage = ({
  startAtTop,
  inertia,
  springConfig = {}
}: UseScrollPercentageProps): {
  scrollPercentage: { value: SpringValue<number> };
  ref: ScRef;
  visible: { value: SpringValue<boolean> };
  onScreen: boolean;
} => {
  const visible = useSpring({ from: { value: false } });
  const [onScreen, setOnScreen] = useState(false);
  const ref = useRef<HTMLElement>();

  const getScrollPercentage = useCallback(() => {
    if (!ref.current) {
      return 0;
    }

    const { top, bottom } = ref.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const startY = startAtTop ? top + windowHeight : top;
    let elementHeight = bottom - top;

    if (!startAtTop) {
      elementHeight += windowHeight;
    }

    // the percentage of the element that is visible
    let percentage = (windowHeight - startY) / elementHeight;

    // if the element is above the viewport, the percentage will be negative
    if (percentage < 0) {
      percentage = 0;
    }

    // if the element is below the viewport, the percentage will be greater than 1
    if (percentage > 1) {
      percentage = 1;
    }
    return percentage;
  }, [startAtTop]);

  const scrollPercentage = useSpring({
    from: { value: getScrollPercentage() },
    config: springConfig
  });

  useEffect(() => {
    let observer: IntersectionObserver;

    if (ref.current) {
      // Create a new Intersection Observer
      observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          setOnScreen(entry.isIntersecting);
        });
      });

      observer.observe(ref.current);
    }

    const handleScroll = () => {
      const percentage = getScrollPercentage();

      if (typeof percentage === 'undefined') {
        return;
      }

      visible.value.start(percentage > 0);

      if (inertia) {
        scrollPercentage.value.start(percentage);
      } else {
        scrollPercentage.value.set(percentage);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
      observer?.disconnect();
    };
  }, [ref, startAtTop, scrollPercentage, inertia, onScreen, visible, getScrollPercentage]);

  return {
    ref: ref as ScRef,
    scrollPercentage,
    visible,
    onScreen
  };
};
