import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import styled from 'styled-components';
import Icon from 'components/Icons/Icon';

interface CL {
  children?: React.ReactElement;
}

const Main = styled.main`
  padding-top: 71px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColorOpacities.fg50};
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.md`
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 0;
  `};
`;

const CapabilitiesButtonContainer = styled.div`
  grid-column: 1 / span 2;

  ${({ theme }) => theme.mq.md`
    display: none;
  `};
`;

const CapabilitiesMobileMenuButton = styled.button`
  font-family: 'SpaceMonoRegular';
  font-weight: 400;
  font-size: 16px;
  float: right;
  padding: 0.5rem 1.375rem;
  margin: 1.875rem 1.5rem 0.5rem auto;
  width: 13.875rem;
  border: 1px solid ${({ theme }) => theme.themeColors.fg};
  border-radius: 54px;
  background: transparent;
  color: ${({ theme }) => theme.themeColors.fg};
  display: flex;
  justify-content: space-between;
`;

const CapabilitiesLayout: React.FC<CL> = ({ children }) => {
  const [menuVisibility, setMenuVisibility] = useState(false);

  useEffect(() => {
    document.body.style.overflow = menuVisibility ? 'hidden' : 'unset';
  });

  return (
    <>
      <Header />
      <Main>
        <div>{children}</div>
        <CapabilitiesButtonContainer>
          <CapabilitiesMobileMenuButton
            type="button"
            onClick={() => setMenuVisibility(!menuVisibility)}
          >
            What we do
            <Icon name={'SmallMenu'} />
          </CapabilitiesMobileMenuButton>
        </CapabilitiesButtonContainer>
      </Main>
      <Footer />
    </>
  );
};

export default CapabilitiesLayout;
