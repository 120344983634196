import React from 'react';
import Header from 'components/Header';

interface DL {
  children?: React.ReactElement;
}

const LandingLayout: React.FC<DL> = ({ children }) => {
  return (
    <>
      <Header landing={true} />
      {children}
    </>
  );
};

export default LandingLayout;
