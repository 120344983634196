import React, { useEffect, Fragment, ReactNode, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import type { NextPage, GetStaticProps } from 'next';
import omit from 'lodash/omit';
import LandingPage from 'components/landing';
import defaultsDeep from 'lodash/defaultsDeep';
import { SubTheme } from '@refract-ui/core';
import * as Blocks from 'blocks';
import { client } from '.tina/__generated__/client';
import { Page } from '.tina/__generated__/types';
import { useTina } from 'tinacms/dist/react';
import themes from 'theme';
import { NextSeo } from 'next-seo';
import reporter from '../lib/analytics';
import { useRouter } from 'next/router';
import layouts from 'layouts';
import { motion, AnimatePresence } from 'framer-motion';

interface PageWrapper {
  page: Page;
}

interface IPage {
  data: PageWrapper;
  query: string;
  variables: object;
}

interface SubThemeProps {
  theme: DefaultTheme;
  children: ReactNode;
}
const StyledSubTheme = styled(SubTheme)<SubThemeProps>`
  background: ${({ theme }) => theme.themeColors.bg};
  // this fixes the black boxes at the bottom of the project pages
  // for some reason, having a border makes the background take the entire span like its supposed to.
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.bg};
`;

interface IMaybeThemed {
  theme?: DefaultTheme;
  children: React.ReactNode;
}

const variants = {
  initial: {
    opacity: 0
  },
  out: {
    opacity: 0,
    transition: {
      duration: 0
    }
  },
  in: {
    opacity: 1,
    transition: {
      duration: 0.33
    }
  }
};

// if theme is provided, render SubTheme with given theme
const MaybeThemed: React.FC<IMaybeThemed> = ({ theme, children }: IMaybeThemed) =>
  theme ? (
    <StyledSubTheme theme={theme}>{children}</StyledSubTheme>
  ) : (
    <Fragment>{children}</Fragment>
  );

const PageRoot: NextPage<IPage> = ({ data, query, variables }: IPage) => {
  const page = useTina({
    data: data,
    query: query,
    variables: variables
  }).data.page;
  const [pageTheme, setPageTheme] = useState<DefaultTheme | undefined>(undefined);
  const [pageThemeName, setPageThemeName] = useState<string | undefined>(undefined);
  const pageLayoutName = (data.page.pageConfig?.layout || 'DefaultLayout') as keyof typeof layouts;
  const PageLayout = layouts[pageLayoutName] as React.FC<typeof data.page.pageConfig>;
  const router = useRouter();

  useEffect(() => {
    // set page theme after navigation to new route to prevent style jumping
    const pageThemeName = data.page.pageConfig?.theme as keyof typeof themes;
    setPageTheme(themes[pageThemeName] as DefaultTheme);
    setPageThemeName(pageThemeName);
    // log pageviews to analytics services
    try {
      reporter?.page();
    } catch (err) {
      console.log('page view error.');
    }
  }, [router.asPath]);

  return (
    <AnimatePresence initial={false}>
      <motion.div variants={variants} initial="initial" animate="in" exit="out" key={router.asPath}>
        <MaybeThemed theme={pageTheme}>
          <NextSeo
            title={data.page.pageConfig?.title as string}
            description={data.page.pageConfig?.description as string}
          />
          {router.asPath === '/' ? (
            <LandingPage />
          ) : (
            <PageLayout {...data.page.pageConfig}>
              <>
                {page?.pageSections?.map((props: any, idx: number) => {
                  const name = props?.__typename?.replace(
                    /^PagePageSections/,
                    ''
                  ) as keyof typeof Blocks;
                  const key = `${props?.__typename}/${idx}`;
                  const Block = Blocks[name];
                  const blockProps = omit(props, ['__typename', 'blockConfig']);
                  const blockThemeName = (props?.blockConfig?.theme ||
                    pageThemeName ||
                    'dark') as keyof typeof themes;
                  const blockSettings = omit(props?.blockConfig, ['__typename', 'theme']) || {};
                  const blockTheme = defaultsDeep(
                    { gfx: { block: { settings: blockSettings } } },
                    themes[blockThemeName]
                  ) as DefaultTheme;
                  return (
                    <MaybeThemed key={key} theme={blockTheme}>
                      <Block {...blockProps} />
                    </MaybeThemed>
                  );
                })}
              </>
            </PageLayout>
          )}
        </MaybeThemed>
      </motion.div>
    </AnimatePresence>
  );
};

export async function getStaticPaths() {
  const { data } = await client.queries.pageConnection({ first: -1 });
  const paths =
    data?.pageConnection?.edges?.map(page => {
      const slug = (page?.node?._sys.filename as string) || 'home';
      return {
        params: { slug: slug === 'home' ? [] : slug.split('/') }
      };
    }) || [];
  return { paths, fallback: false };
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const slug = params?.slug?.[0] || 'home';
  const { data, query, variables } = await client.queries.page({ relativePath: `${slug}.mdx` });
  return {
    props: {
      slug,
      data,
      query,
      variables
    }
  };
};

export default PageRoot;
