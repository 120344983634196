import React, { useState, useCallback, useEffect } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import tc from 'tinycolor2';
import Footer from 'components/Footer';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { useContactModal } from 'context/Contact';
import { animated, useSpringValue } from '@react-spring/web';

import HeaderLogoSvg from 'public/svg/logo.svg';
import MenuToggleSvg from 'public/svg/menu-closed.svg';

const HeaderLogo = styled(HeaderLogoSvg)`
  width: 70px;
  height: 70px;

  ${({ theme: { themeColors } }) => css`
    path {
      fill: ${themeColors.fg};
    }
  `}
`;

const MenuToggle = styled(MenuToggleSvg)`
  ${({ theme: { themeColors } }) => css`
    path {
      stroke: ${themeColors.fg};
    }
  `}
`;

const Container = styled.div<{ $pad: boolean }>`
  display: grid;
  align-self: stretch;
  min-height: 100svh;
  grid-template-rows: max-content 1fr max-content;
  ${({ $pad }) =>
    $pad &&
    css`
      padding-top: 70px;
    `}
`;

const Header = styled.header<{ $sticky: boolean }>`
  border-bottom: solid rgba(127, 127, 127, 0.5) 1px;
  display: flex;
  position: fixed;
  top: 0;
  background: ${({ theme: { themeColors } }) => themeColors.bg};
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 3;

  ${({ $sticky }) =>
    $sticky &&
    css`
      position: sticky;
    `}
`;

const BrandContainer = styled.div`
  grid-area: brand;
`;

const Brand = styled(Link)`
  position: relative;
  width: 70px;
  height: 70px;

  img {
    object-fit: contain;
  }
`;

const HeaderMenuToggle = styled.button`
  border: 0;
  border-left: solid rgba(127, 127, 127, 0.5) 1px;
  background: transparent;
  outline: 0;
  width: 70px;

  ${({ theme: { mq } }) => mq.md`
    display: none;
  `}
`;

const HeaderMenuContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  overflow: hidden;
  display: grid;
  align-content: center;

  ${({ theme: { mq } }) => mq.md`
    position: relative;
    top: 0;
  `}
`;

const HeaderMenu = styled(animated.ul)`
  grid-area: menu;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  list-style-type: none;
  gap: 1rem;
  padding: 1rem;
  position: relative;
  top: -100%;

  ${({ theme: { mq } }) => mq.md`
    transition: unset;
    transform: translate3d(0, 0, 0);
    padding: unset;
    align-items: center;
    padding: 0 1rem;
    top: 0;
    opacity: 1 !important;
    transform: unset !important;
  `}
`;

const HeaderMenuItem = styled.li`
  padding: 0;
  margin: 0 !important;
`;

const menuLinkCss = css`
  color: ${({ theme: { themeColors } }) => themeColors.fg};
  outline: 0;
  padding: 0;
  border: 0;
  background: transparent;
  color: ${({ theme: { themeColors } }) => themeColors.fg} !important;
  font-weight: 500 !important;
  font-family: SpaceGroteskRegular;
  font-size: 1rem !important;

  &:hover {
    text-decoration: underline;
  }
`;

const HeaderMenuLink = styled(Link)`
  ${menuLinkCss}
`;

const HeaderMenuButton = styled.button`
  ${menuLinkCss}
`;

const HeaderScreen = styled(animated.div)<{ $open: boolean }>`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: ${({ theme: { themeColors } }) => tc(themeColors.bg).setAlpha(0.7).toRgbString()};
  z-index: 2;
  backdrop-filter: blur(2px);

  ${({ $open }) =>
    $open &&
    css`
      display: none;
    `}

  ${({ theme: { mq } }) => mq.md`
    display: none !important;
  `}
`;

const Main = styled.main`
  position: relative;
  max-width: 100%;
`;

interface AppContainerProps {
  className?: string;
  children?: React.ReactNode;
  showFooter?: boolean;
  pad?: boolean;
  sticky?: boolean;
}

export const AppContainer: React.FC<AppContainerProps> = ({
  children,
  className,
  pad = true,
  sticky = false,
  showFooter = true
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const screenOpacity = useSpringValue(0);
  const { contactModalOpen, showContactModal } = useContactModal();
  useLockBodyScroll(menuOpen || contactModalOpen);

  useEffect(() => {
    setMenuOpen(false);
  }, []);

  const toggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
    screenOpacity.start(menuOpen ? 0 : 1);
  }, [screenOpacity, menuOpen]);

  // close menu if window is resized past mobile
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMenuOpen(false);
        screenOpacity.start(0);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenOpacity]);

  return (
    <Container $pad={pad} className={className}>
      <Header $sticky={sticky}>
        <BrandContainer>
          <Brand href="/">
            <HeaderLogo width={70} height={70} viewBox="0 0 165 165" />
          </Brand>
        </BrandContainer>

        <HeaderMenuToggle onClick={toggleMenu}>
          <MenuToggle width={40} height={40} viewBox="0 0 40 40" />
        </HeaderMenuToggle>

        <HeaderMenuContainer>
          <HeaderMenu
            style={{
              opacity: screenOpacity,
              transform: screenOpacity.to(val => `translate3d(0, ${val * 100}%, 0)`)
            }}
          >
            <HeaderMenuItem>
              <HeaderMenuLink href="/projects">Work</HeaderMenuLink>
            </HeaderMenuItem>

            <HeaderMenuItem>
              <HeaderMenuLink href="/team">Team</HeaderMenuLink>
            </HeaderMenuItem>

            <HeaderMenuItem>
              <HeaderMenuButton onClick={showContactModal}>Contact</HeaderMenuButton>
            </HeaderMenuItem>
          </HeaderMenu>
        </HeaderMenuContainer>
      </Header>

      <Main>{children}</Main>

      {showFooter && <Footer />}

      <HeaderScreen
        onClick={toggleMenu}
        style={{
          opacity: screenOpacity,
          display: screenOpacity.to(val => (val > 0 ? 'block' : 'none'))
        }}
        $open={menuOpen}
      />
    </Container>
  );
};

export default styled(AppContainer)``;
