import DefaultLayout from 'components/landing/AppContainer';
import CapabilitiesLayout from './CapabilitiesLayout';
import CapabilityCanvasLayout from './CapabilityCanvasLayout';
import LandingLayout from './LandingLayout';
import TeamLayout from './TeamLayout';

const layouts = {
  DefaultLayout,
  CapabilitiesLayout,
  CapabilityCanvasLayout,
  LandingLayout,
  TeamLayout
};

export default layouts;
