import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import tc from 'tinycolor2';
import { blockPadH, blockW } from 'theme/mixins/padding';

import FooterLogoMobileSvg from 'public/svg/footer-logo-mobile.svg';
import FooterLogoDesktopSvg from 'public/svg/footer-logo-desktop.svg';

const FooterContainer = styled.div`
  display: grid;
  align-content: space-between;
  justify-items: center;
  z-index: 2;
  gap: 1rem;
  ${blockPadH()};
  padding-bottom: 1rem;
`;

const FooterContent = styled.div`
  ${blockW()};
  width: 100%;
  border: solid #666 1px;
  background: ${({ theme: { themeColors } }) => tc(themeColors.bg).setAlpha(0.5).toRgbString()};
  color: ${({ theme: { themeColors } }) => themeColors.fg};
  position: relative;
  left: 0;
  bottom: 0;
  border-radius: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr max-content;
  padding: 1rem;

  ${({ theme: { mq } }) => mq.sm`
    grid-template-columns: 1fr max-content;
  `}

  ${({ theme: { mq } }) => mq.md`
    padding: 1.5rem;
    grid-template-columns: 1fr 1fr 1fr max-content;
    justify-content: space-between;
    gap: 2rem;
  `}

  small {
    color: ${({ theme: { themeColors } }) => tc(themeColors.fg).setAlpha(0.8).toRgbString()};
    font-size: 0.75rem;
    line-height: 1;
  }

  a {
    transition: opacity 0.2s;
    opacity: 1;
    font-family: SpaceGroteskLight;
    font-weight: 300;
    color: ${({ theme: { themeColors } }) => themeColors.fg};

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      opacity: 0.7;
    }
  }
`;

const FooterColumn = styled.div`
  display: grid;
  align-content: space-between;

  ${({ theme: { mq } }) => mq.ltSm`
    &:first-child, &:last-child {
      grid-column: span 2;
    }
  `}

  &:last-child {
    text-align: right;
    justify-content: end;

    ${({ theme: { mq } }) => mq.ltSm`
      justify-content: space-between;
      grid-template-columns: 1fr max-content;
      text-align: left;
    `}
  }

  ${({ theme: { mq } }) => mq.smOnly`
    &:nth-child(2) {
      text-align: right;
      justify-content: end;
    }
  `}

  ${({ theme: { mq } }) => mq.md`
    grid-template-rows: 3rem max-content;
  `}
`;

const FooterColumnHeader = styled.h5`
  font-size: 1.125rem !important;
  color: ${({ theme: { themeColors } }) => tc(themeColors.fg).setAlpha(0.5).toRgbString()};
  padding: 0;
  margin: 0;
  line-height: 0.9 !important;
`;

const FooterColumnList = styled.ul`
  list-style-type: none;
  font-size: 1.125rem;
  align-self: end;
  padding-left: 0 !important;
`;

const FooterColumnItem = styled.li`
  margin: 0 !important;
`;

const LogoContainer = styled.div`
  ${blockW()};
  overflow: hidden;
  width: 100%;

  ${({ theme: { themeColors } }) => css`
    svg path {
      fill: ${themeColors.fg};
    }
  `}
`;

const FooterLogoMobile = styled(FooterLogoMobileSvg)`
  aspect-ratio: 369 / 70;
  display: block;

  ${({ theme: { themeColors } }) => css`
    path:nth-child(2) {
      fill: ${themeColors.bg};
    }
  `}

  ${({ theme: { mq } }) => mq.md`
    display: none;
  `}
`;

const FooterLogoDesktop = styled(FooterLogoDesktopSvg)`
  display: none;
  aspect-ratio: 1391 / 124;

  ${({ theme: { mq } }) => mq.md`
    display: block;
  `}
`;

interface IFooter {
  teamLayout?: boolean;
}

const Footer: React.FC<IFooter> = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterColumn>
          <FooterColumnHeader>Contact</FooterColumnHeader>

          <FooterColumnList>
            <FooterColumnItem>
              <a href="mailto: dispatch@graveflex.com">dispatch@graveflex.com</a>
            </FooterColumnItem>
            <FooterColumnItem>
              <a href="tel: +13122100686">(312) 210-0686</a>
            </FooterColumnItem>
          </FooterColumnList>
        </FooterColumn>

        <FooterColumn>
          <FooterColumnHeader>Follow Us</FooterColumnHeader>

          <FooterColumnList>
            <FooterColumnItem>
              <a
                href="https://www.instagram.com/graveflex_/?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </FooterColumnItem>
            <FooterColumnItem>
              <a
                href="https://www.linkedin.com/company/graveflex/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </FooterColumnItem>
          </FooterColumnList>
        </FooterColumn>

        <FooterColumn>
          <FooterColumnHeader>Links</FooterColumnHeader>

          <FooterColumnList>
            <FooterColumnItem>
              <Link href="/projects">Work</Link>
            </FooterColumnItem>
            <FooterColumnItem>
              <Link href="/team">Team</Link>
            </FooterColumnItem>
          </FooterColumnList>
        </FooterColumn>

        <FooterColumn>
          <FooterColumnHeader>
            <small>
              Graveflex
              <br />
              All rights reserved
            </small>
          </FooterColumnHeader>

          <FooterColumnList>
            <FooterColumnItem>
              <small>&copy; {new Date().getFullYear()}</small>
            </FooterColumnItem>
          </FooterColumnList>
        </FooterColumn>
      </FooterContent>

      <LogoContainer>
        <FooterLogoMobile viewBox="0 0 369 70" />
        <FooterLogoDesktop viewBox="0 0 1391 124" />
      </LogoContainer>
    </FooterContainer>
  );
};

export default Footer;
