import { useEffect, useState, useRef } from 'react';

const useIsVisible = <T extends HTMLElement = HTMLElement>() => {
  const ref = useRef<T | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let observer: IntersectionObserver;

    if (ref.current) {
      // Create a new Intersection Observer
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          setIsVisible(entry.isIntersecting);
        });
      });

      observer.observe(ref.current);
    }

    return () => {
      observer?.disconnect();
    };
  }, []);

  return { ref, isVisible };
};

export default useIsVisible;
