import React, { useRef, useMemo } from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { animated, type SpringValue } from '@react-spring/three';

interface StarsProps {
  progress: { value: SpringValue<number> };
  starsCount?: number;
  starSize?: number;
  radius?: number;
}

const Stars: React.FC<StarsProps> = ({ starsCount = 1000, starSize = 0.5 }) => {
  const meshRef = useRef<THREE.Group>(null);

  // Generate random positions for the stars
  const starsPositions = useMemo(() => {
    const positions = [];
    for (let i = 0; i < starsCount; i++) {
      const r = 300 + Math.random() * 300 - 300 / 2; // Distance from the center of the scene
      const theta = THREE.MathUtils.randFloatSpread(360); // Random angle for the latitude
      const phi = THREE.MathUtils.randFloatSpread(180); // Random angle for the longitude

      const x = r * Math.sin(theta) * Math.cos(phi);
      const y = r * Math.sin(theta) * Math.sin(phi);
      const z = r * Math.cos(theta);

      positions.push(x, y, z);
    }
    return new Float32Array(positions);
  }, [starsCount]);

  // Update loop for animation or dynamic changes
  useFrame(() => {
    if (meshRef.current) {
      // Example of dynamic update, rotate the star field slowly
      meshRef.current.rotation.x += 0.0003;
      meshRef.current.rotation.z += 0.0002;
      meshRef.current.rotation.y += 0.0002;
    }
  });

  return (
    <animated.group ref={meshRef}>
      <points>
        <bufferGeometry attach="geometry">
          <bufferAttribute
            attach="attributes-position"
            count={starsPositions.length / 3}
            array={starsPositions}
            itemSize={3}
          />
        </bufferGeometry>
        <pointsMaterial
          attach="material"
          size={starSize}
          sizeAttenuation
          color="#777"
          transparent={false}
          alphaTest={0.5}
          opacity={1.0}
        />
      </points>
    </animated.group>
  );
};

export default Stars;
