import React from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { Canvas, useFrame } from '@react-three/fiber';
import { a, SpringValue } from '@react-spring/web';
import { animated, Globals } from '@react-spring/three';
import * as easing from 'maath/easing';
import CTAButton from 'components/landing/CTAButton';
import Stars from 'components/landing/Stars';
import Image from 'next/image';
import { useContactModal } from 'context/Contact';
import { useElementScrollPercentage } from 'hooks/useElementScrollPercentage';

Globals.assign({
  frameLoop: 'always'
});

const FadeIn = styled.div`
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20lvh;
  z-index: 1;
`;

const FadeOut = styled.div`
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20lvh;
  z-index: 1;
`;

const CanvasContainer = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
`;

const CanvasContent = styled.div`
  position: sticky;
  top: 0;
  height: 100lvh;
  width: 100%;
`;

const Container = styled.section<{ $debug?: boolean }>`
  position: relative;
  height: 350lvh;
  width: 100%;

  ${({ $debug }) =>
    $debug &&
    css`
      border: dotted blue 4px;

      ${CanvasContent} {
        border: dotted blanchedalmond 4px;
      }
    `}
`;

const TextContainer = styled.div`
  position: absolute;
  top: 30lvh;
  height: 200lvh;
  width: 100%;
`;

const TextContent = styled.div`
  border-color: pink;
  position: sticky;
  top: 0;
  height: 100lvh;
  width: 100%;
  display: grid;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  z-index: 2;

  h4 {
    color: #878787;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 0.045rem;
    text-transform: uppercase;
    font-family: SpaceMonoRegular;
  }

  p {
    font-size: 8vw;
    font-weight: 400;
    color: #fff;
    line-height: 1.1;
  }

  ${CTAButton} {
    margin-top: 1rem;
    width: auto;
    justify-self: center;
  }

  ${({ theme: { mq } }) => mq.md`
    h4 {
      font-size: 1.375rem;
      letter-spacing: 0.055rem;
    }

    p {
      font-size: 5.625rem;
      letter-spacing: -0.28125rem;
    }

    ${CTAButton} {
      margin-top: 2rem;
    }
  `}
`;

const FooterContainer = styled.div`
  height: 100lvh;
  position: sticky;
  display: grid;
  align-content: space-between;
  justify-items: center;
  top: 0;
  width: 100%;
  padding: 0.5rem;
  z-index: 2;

  ${({ theme: { mq } }) => mq.md`
    padding: 2rem;
  `}
`;

const FooterContent = styled(a.div)`
  border: solid #666 1px;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  left: 0;
  bottom: 0;
  margin: 1rem;
  width: calc(100% - 2rem);
  max-width: 1200px;
  padding: 1rem;
  border-radius: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr max-content;

  ${({ theme: { mq } }) => mq.sm`
    grid-template-columns: 1fr max-content;
  `}

  ${({ theme: { mq } }) => mq.md`
    width: calc(100% - 4rem);
    padding: 1.5rem;
    grid-template-columns: 1fr 1fr 1fr max-content;
    justify-content: space-between;
    gap: 2rem;
  `}

  small {
    color: #878787;
    font-size: 0.75rem;
    line-height: 1;
  }

  a {
    transition: opacity 0.2s;
    opacity: 1;
    font-family: SpaceGroteskLight;
    font-weight: 300;

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      opacity: 0.7;
    }
  }
`;

const FooterColumn = styled.div`
  display: grid;
  align-content: space-between;

  ${({ theme: { mq } }) => mq.ltSm`
    &:first-child, &:last-child {
      grid-column: span 2;
    }
  `}

  &:last-child {
    text-align: right;
    justify-content: end;

    ${({ theme: { mq } }) => mq.ltSm`
      justify-content: space-between;
      grid-template-columns: 1fr max-content;
      text-align: left;
    `}
  }

  ${({ theme: { mq } }) => mq.smOnly`
    &:nth-child(2) {
      text-align: right;
      justify-content: end;
    }
  `}

  ${({ theme: { mq } }) => mq.md`
    grid-template-rows: 3rem max-content;
  `}
`;

const FooterColumnHeader = styled.h5`
  font-size: 1.125rem;
  color: #878787;
  padding: 0;
  margin: 0;
  line-height: 0.9;
`;

const FooterColumnList = styled.ul`
  list-style-type: none;
  font-size: 1.125rem;
  align-self: end;
`;

const FooterColumnItem = styled.li`
  margin: 0;
`;

const LogoContainer = styled(a.div)`
  position: sticky;
  overflow: hidden;
  top: 70px;
  left: 0;
  width: calc(100% - 2rem);
  max-width: 100vw;
  margin: 0 1rem;
  max-width: 1200px;
  padding: 1rem 0;

  ${({ theme: { mq } }) => mq.md`
    width: calc(100% - 4rem);
    margin: 2rem auto;
    padding: 2rem 0;
  `}
`;

const LogoInner = styled.div`
  max-width: 1400px;
  position: relative;
  height: 100%;
  aspect-ratio: 1391/124;
`;

const Logo = styled(a(Image))``;

interface RigProps {
  progress: { value: SpringValue<number> };
}

const Rig: React.FC<RigProps> = ({ progress }) => {
  useFrame((state, dt) => {
    const z = progress.value.to([0, 0.1, 1], [110, 110, 70]).get();
    const y = progress.value.to([0, 0.1, 0.3, 1], [10, 10, 1, 1]).get();
    const pitch = progress.value.to([0.1, 0.3], [-0.5, 0], 'clamp').get();
    easing.damp3(state.camera.position, [1, y, z], 0.25, dt);
    easing.dampE(state.camera.rotation, [Math.PI * pitch, 0, 0], 0.25, dt);
  });

  return null;
};

interface OrbProps {
  progress: { value: SpringValue<number> };
}

const Orb: React.FC<OrbProps> = ({ progress }) => {
  return (
    <animated.mesh
      scale={450}
      position-z={-900}
      position-x={progress.value.to([0.3, 1], [-200, 100])}
      position-y={progress.value.to([0.3, 1], [-550, 100])}
    >
      <sphereGeometry args={[1, 64, 64]} />
      <meshStandardMaterial color="#fff" />
    </animated.mesh>
  );
};

const Plane: React.FC = () => {
  return (
    <mesh rotation-x={Math.PI * -0.5} scale={250} position-y={-1}>
      <planeGeometry />
      <meshStandardMaterial color="#000000" />
    </mesh>
  );
};

interface GridProps {
  debug?: boolean;
}

const Grid: React.FC<GridProps> = ({ debug }) => {
  const { ref, scrollPercentage, onScreen } = useElementScrollPercentage({
    startAtTop: true
  });

  const { showContactModal } = useContactModal();

  return (
    <Container ref={ref} $debug={debug}>
      <FadeIn />

      <CanvasContainer>
        <CanvasContent>
          {!debug && (
            <Canvas
              camera={{ position: [1, 10, 110], rotation: [Math.PI * -0.5, 0, 0] }}
              frameloop={onScreen ? 'always' : 'never'}
            >
              <ambientLight />
              <Rig progress={scrollPercentage} />
              <Plane />
              <Orb progress={scrollPercentage} />
              <Stars radius={500} progress={scrollPercentage} starsCount={2000} />
              <gridHelper args={[30, 300, '#444', '#444']} scale={10} />
            </Canvas>
          )}
        </CanvasContent>

        <FooterContainer>
          <LogoContainer>
            <LogoInner>
              <Logo
                style={{
                  opacity: scrollPercentage.value.to([0.62, 0.72], [0, 1], 'clamp'),
                  transform: scrollPercentage.value
                    .to([0.62, 0.72], [100, 0], 'clamp')
                    .to(val => `translate3d(0, -${val}%, 0)`)
                }}
                src="/svg/footer-logo-desktop.svg"
                alt="Graveflex"
                fill
              />
            </LogoInner>
          </LogoContainer>

          <FooterContent
            style={{
              opacity: scrollPercentage.value.to([0.62, 0.72], [0, 1], 'clamp'),
              transform: scrollPercentage.value
                .to([0.62, 0.72], [100, 0], 'clamp')
                .to(val => `translate3d(0, ${val}%, 0)`)
            }}
          >
            <FooterColumn>
              <FooterColumnHeader>Contact</FooterColumnHeader>

              <FooterColumnList>
                <FooterColumnItem>
                  <a href="mailto: dispatch@graveflex.com">dispatch@graveflex.com</a>
                </FooterColumnItem>
                <FooterColumnItem>
                  <a href="tel: +13122100686">(312) 210-0686</a>
                </FooterColumnItem>
              </FooterColumnList>
            </FooterColumn>

            <FooterColumn>
              <FooterColumnHeader>Follow Us</FooterColumnHeader>

              <FooterColumnList>
                <FooterColumnItem>
                  <a
                    href="https://www.instagram.com/graveflex_/?hl=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </FooterColumnItem>
                <FooterColumnItem>
                  <a
                    href="https://www.linkedin.com/company/graveflex/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LinkedIn
                  </a>
                </FooterColumnItem>
              </FooterColumnList>
            </FooterColumn>

            <FooterColumn>
              <FooterColumnHeader>Links</FooterColumnHeader>

              <FooterColumnList>
                <FooterColumnItem>
                  <Link href="/projects">Work</Link>
                </FooterColumnItem>
                <FooterColumnItem>
                  <Link href="/team">Team</Link>
                </FooterColumnItem>
              </FooterColumnList>
            </FooterColumn>

            <FooterColumn>
              <FooterColumnHeader>
                <small>
                  Graveflex
                  <br />
                  All rights reserved
                </small>
              </FooterColumnHeader>

              <FooterColumnList>
                <FooterColumnItem>
                  <small>&copy; {new Date().getFullYear()}</small>
                </FooterColumnItem>
              </FooterColumnList>
            </FooterColumn>
          </FooterContent>
        </FooterContainer>
      </CanvasContainer>

      <TextContainer>
        <TextContent>
          <h4>Contact Us</h4>
          <p>
            Let&apos;s build
            <br /> the next big thing
            <br /> together
          </p>

          <CTAButton onClick={showContactModal}>Get in touch</CTAButton>
        </TextContent>
      </TextContainer>

      <FadeOut />
    </Container>
  );
};

export default Grid;
