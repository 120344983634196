import React, { useEffect, useCallback, useRef } from 'react';
import Image from 'next/image';
import styled, { css } from 'styled-components';
import tc from 'tinycolor2';
import { animated } from '@react-spring/web';
import useIsVisible from 'hooks/useIsVisible';
import { useElementScrollPercentage } from 'hooks/useElementScrollPercentage';

const Container = styled.div`
  position: sticky;
  top: 0;
  margin: 0 auto;
  max-width: 1400px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.25rem;
  padding: 0 1rem;

  ${({ theme: { mq } }) => mq.md`
    grid-template-columns: 1fr 1fr;

    @media screen and (min-height: 850px) {
      height: 100svh;
      padding-top: calc(1.5rem + 70px);
      padding-bottom: 1.5rem;
    }
  `}
`;

const ImageSlidesContainer = styled.div`
  height: 100%;
  position: relative;
  align-self: stretch;
  border-radius: 5rem;
  overflow: hidden;

  ${({ theme: { mq } }) => mq.md`
    border-radius: 10rem;
  `}
`;

const VideoEdge = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-clip: padding-box;
  }
`;

const featheredEdge = (color: string) => css`
  background-color: ${color};

  ${VideoEdge} {
    &:after {
      background: linear-gradient(to top, ${tc(color).setAlpha(0).toRgbString()} 85%, ${color}),
        linear-gradient(to bottom, ${tc(color).setAlpha(0).toRgbString()} 85%, ${color});
    }
  }}
`;

const OfficeImageContainer = styled(animated.div)`
  position: relative;
  overflow: hidden;
  display: grid;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  height: 100%;
  right: 0;

  &:first-child {
    ${featheredEdge('rgba(75, 74, 77, 1)')}
  }

  &:last-child {
    ${featheredEdge('rgba(26, 24, 22, 1)')}
  }

  &:nth-child(2) {
    ${featheredEdge('rgba(4, 41, 62, 1)')}
  }

  video {
    position: relative;
    display: block;
    aspect-ratio: 1/1;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    background-position: left center;
  }

  ${({ theme: { mq } }) => mq.ltMd`
    aspect-ratio: 1/1;
    clip-path: none !important;
    display: none;

    &:first-child {
      display: block;
    }
  `}

  ${({ theme: { mq } }) => mq.md`
    align-self: stretch;
    position: absolute;
    width: 100%;
    height: 100%;

    @media screen and (max-height: 850px) {
      height: 50svh;
      clip-path: none !important;
      display: none;

      &:first-child {
        display: grid;
        height: 100%;
      }
    }
  `}
`;

const SlideContainer = styled(animated.section)<{ $debug?: boolean }>`
  ${({ $debug }) =>
    $debug &&
    css`
      ${OfficeImageContainer} {
        background: pink;

        &:first-child {
          background: magenta;
        }

        &:last-child {
          background: cyan;
        }
      }
    `}

  ${({ theme: { mq } }) => mq.md`
    @media screen and (min-height: 850px) {
      height: 220svh;
    }
  `}
`;

interface VideoProps extends React.ComponentProps<'video'> {
  src: string;
  alt: string;
  innerRef: React.MutableRefObject<HTMLVideoElement | null>;
}

const VideoPlayer = ({ innerRef, src, alt, ...props }: Partial<VideoProps>) => {
  const { ref: videoRef, isVisible } = useIsVisible<HTMLVideoElement>();

  useEffect(() => {
    if (videoRef.current) {
      if (isVisible) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isVisible, videoRef]);

  return (
    <VideoEdge>
      <video
        autoPlay
        muted
        loop
        controls={false}
        playsInline
        preload="auto"
        ref={current => {
          videoRef.current = current;
          if (innerRef) {
            (innerRef as React.MutableRefObject<HTMLVideoElement>).current =
              current as HTMLVideoElement;
          }
        }}
        {...props}
      >
        <source src={src} type="video/mp4" />
        <p>{alt}</p>
      </video>
    </VideoEdge>
  );
};

const QuoteSlidesContainer = styled.div`
  position: relative;
  background: #171719;
  border-radius: 5rem;

  ${({ theme: { mq } }) => mq.md`
    border-radius: 10rem;
  `}
`;

const QuoteContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: grid;
  align-content: center;
  justify-content: center;
  gap: 1rem;
  padding: 5rem 2rem;
  overflow: hidden;

  ${({ theme: { mq } }) => mq.lg`
    padding: 5rem 4.5rem;
  `}

  ${({ theme: { mq } }) => mq.md`
    transition: filter 0.2s, opacity 0.2s, transform 0.2s;

    @media screen and (max-height: 850px) {
      position: relative;
      opacity: unset !important;
      transform: unset !important;
      transition: unset !important;
      filter: unset !important;
      display: none;

      &:first-child {
        display: block;
      }
    }
  `}

  ${({ theme: { mq } }) => mq.ltMd`
    position: relative;
    opacity: unset !important;
    transform: unset !important;
    filter: unset !important;
    display: none;

    &:first-child {
      display: block;
    }
  `}
`;

const QuoteIconContainer = styled.div`
  margin-bottom: 2rem;
`;

const QuoteText = styled.div`
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;

  ${({ theme: { mq } }) => mq.xl`
    font-size: 2.25rem;
    line-height: 1.1;
  `}
`;

const QuoteAttribution = styled.p`
  margin: 3.875rem 0 0;
  padding: 0;
  color: #878787;
  font-family: SpaceGroteskLight;
  font-size: 1.125rem;
  line-height: 1.2;
  align-self: end;
`;

interface QuoteProps {
  debug?: boolean;
}

const Quote: React.FC<QuoteProps> = ({ debug }) => {
  const timestamp = useRef(0);
  const { ref, scrollPercentage } = useElementScrollPercentage({ startAtTop: true });
  const { ref: fadeRef, scrollPercentage: fadeScrollPercentage } = useElementScrollPercentage({
    startAtTop: false,
    inertia: true
  });

  // first video that subsequent videos should sync to
  const firstVideoRef = useRef<HTMLVideoElement | null>(null);
  const secondVideoRef = useRef<HTMLVideoElement | null>(null);
  const thirdVideoRef = useRef<HTMLVideoElement | null>(null);

  const syncVideos = useCallback(() => {
    if (!firstVideoRef.current || !secondVideoRef.current || !thirdVideoRef.current) {
      return;
    }

    const currentTime = firstVideoRef.current.currentTime;
    secondVideoRef.current.currentTime = currentTime;
    thirdVideoRef.current.currentTime = currentTime;
  }, []);

  // keep videos in sync
  useEffect(() => {
    window.addEventListener('resize', syncVideos);

    return () => {
      window.removeEventListener('resize', syncVideos);
    };
  }, [syncVideos]);

  return (
    <SlideContainer
      $debug={debug}
      ref={ref}
      style={{
        opacity: fadeScrollPercentage.value.to([0.1, 0.3], [0, 1], 'clamp')
      }}
    >
      <Container ref={fadeRef}>
        <ImageSlidesContainer>
          <OfficeImageContainer>
            {!debug && (
              <VideoPlayer
                alt="An iPad displaying the #Halfthestory app"
                src="/videos/HTS-ipad-asset.mp4"
                innerRef={firstVideoRef}
                onTimeUpdate={ev => {
                  const nextTime = (ev.target as HTMLVideoElement).currentTime;

                  if (nextTime < timestamp.current) {
                    syncVideos();
                  }

                  timestamp.current = nextTime;
                }}
              />
            )}
          </OfficeImageContainer>

          <OfficeImageContainer
            style={{
              maskImage: scrollPercentage.value
                .to([0.25, 0.5], [-0.02, 1], 'clamp')
                .to(
                  val =>
                    `linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) ${
                      val * 100
                    }%, rgba(0, 0, 0, 1) ${val * 100 + 2}%, rgba(0, 0, 0, 1))`
                )
            }}
          >
            {!debug && (
              <VideoPlayer
                alt="An app displaying an product page"
                src="/videos/PbP-ipad-asset.mp4"
                innerRef={secondVideoRef}
              />
            )}
          </OfficeImageContainer>

          <OfficeImageContainer
            style={{
              maskImage: scrollPercentage.value
                .to([0, 0.25], [-0.02, 1], 'clamp')
                .to(
                  val =>
                    `linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) ${
                      val * 100
                    }%, rgba(0, 0, 0, 1) ${val * 100 + 2}%, rgba(0, 0, 0, 1))`
                )
            }}
          >
            {!debug && (
              <VideoPlayer
                alt="An iPad displaying the GoodFriend website"
                src="/videos/GF-ipad-asset.mp4"
                innerRef={thirdVideoRef}
              />
            )}
          </OfficeImageContainer>
        </ImageSlidesContainer>

        <QuoteSlidesContainer>
          <QuoteContainer
            style={{
              opacity: scrollPercentage.value.to(val => (val > 0.01 ? 0 : 1)),
              y: scrollPercentage.value.to(val => (val > 0.01 ? -50 : 0)),
              filter: scrollPercentage.value.to(val => `blur(${val > 0.01 ? 8 : 0}px)`)
            }}
          >
            <QuoteText>
              <QuoteIconContainer>
                <Image src="/icons/quotes.svg" width={48} height={34.16} alt="quotation marks" />
              </QuoteIconContainer>
              <span>
                Our project could not have existed without the Graveflex team. They are true
                partners, and have deep expertise that contributed strategically to our product
                while executing at a high level
              </span>
            </QuoteText>

            <QuoteAttribution>
              - Christopher Gonzalez, Former Director of Product @ GoodFriend / Kinship &amp;
              Current Head of Finance @ A.Team
            </QuoteAttribution>
          </QuoteContainer>

          <QuoteContainer
            style={{
              opacity: scrollPercentage.value.to(val => {
                if (val < 0.01) {
                  return 0;
                } else if (val > 0.25) {
                  return 0;
                } else {
                  return 1;
                }
              }),
              y: scrollPercentage.value.to(val => {
                if (val < 0.01) {
                  return 50;
                } else if (val > 0.25) {
                  return -50;
                } else {
                  return 0;
                }
              }),
              filter: scrollPercentage.value.to(val => {
                if (val < 0.01) {
                  return 'blur(8px)';
                } else if (val > 0.25) {
                  return 'blur(8px)';
                } else {
                  return 'blur(0px)';
                }
              })
            }}
          >
            <QuoteText>
              <QuoteIconContainer>
                <Image src="/icons/quotes.svg" width={48} height={34.16} alt="quotation marks" />
              </QuoteIconContainer>
              <span>
                We could not speak more highly for the project management of the Graveflex team.
                They delivered as projected, on time, and always exceeded expectations.
              </span>
            </QuoteText>

            <QuoteAttribution>
              - Lauri Pastrone, Founder &amp; CEO of Peace by Piece
            </QuoteAttribution>
          </QuoteContainer>

          <QuoteContainer
            style={{
              opacity: scrollPercentage.value.to(val => {
                if (val > 0.25) {
                  return 1;
                }

                return 0;
              }),
              y: scrollPercentage.value.to(val => {
                if (val > 0.25) {
                  return 0;
                }

                return 50;
              }),
              filter: scrollPercentage.value.to(val => {
                if (val > 0.25) {
                  return 'blur(0px)';
                }

                return 'blur(8px)';
              })
            }}
          >
            <QuoteText>
              <QuoteIconContainer>
                <Image src="/icons/quotes.svg" width={48} height={34.16} alt="quotation marks" />
              </QuoteIconContainer>
              <span>
                Their proactive approach to addressing potential flaws before they arose was
                commendable and ensured that our platform met the highest standards of usability and
                functionality.
              </span>
            </QuoteText>

            <QuoteAttribution>- Larissa May, CEO of #HalfTheStory</QuoteAttribution>
          </QuoteContainer>
        </QuoteSlidesContainer>
      </Container>
    </SlideContainer>
  );
};

export default Quote;
