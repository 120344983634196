import React from 'react';
import styled from 'styled-components';
import { useElementScrollPercentage } from 'hooks/useElementScrollPercentage';
import { animated } from '@react-spring/web';

const WhatWeDoContainer = styled.section`
  position: relative;
  top: 0;
  height: 120lvh;
  display: grid;
  align-items: start;
  padding: 2rem;
  max-width: 600px;
  margin: -10lvh auto 0;

  ${({ theme: { mq } }) => mq.md`
    max-width: min(65vw, 1050px);
  `}
`;

const WhatWeDoMaskPosition = styled.div`
  position: absolute;
  border-color: purple;
  width: 100%;
  height: 100lvh;
  top: 0;
  pointer-events: none;
  z-index: 0;
`;

const WhatWeDoInner = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 100lvh;
  margin-top: -50lvh;
  display: grid;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 0;
`;

const WhatWeDoEyebrow = styled(animated.h3)`
  color: #666;
  font-weight: 700;
  font-size: 1.125rem;
  text-align: center;
  text-transform: uppercase;
  font-family: SpaceMonoRegular;
`;

const WhatWeDoText = styled(animated.p)`
  color: transparent;
  font-size: 1.875rem;
  line-height: 1.2;
  text-align: center;
  font-family: SpaceGroteskLight;
  background-color: transparent;
  background-clip: text;

  ${({ theme: { mq } }) => mq.md`
    font-size: clamp(2.5rem, 3vw, 3.25rem);
  `}
`;

const WhatWeDo: React.FC = () => {
  const { ref: whatWeDoRef, scrollPercentage: whatWeDoScrollPercentage } =
    useElementScrollPercentage({
      startAtTop: false
    });

  return (
    <WhatWeDoContainer>
      <WhatWeDoInner>
        <WhatWeDoEyebrow
          style={{
            opacity: whatWeDoScrollPercentage.value.to([0.45, 0.55], [0, 1], 'clamp')
          }}
        >
          What We Do
        </WhatWeDoEyebrow>
        <WhatWeDoText
          style={{
            pointerEvents: whatWeDoScrollPercentage.value.to(val => (val > 0 ? 'all' : 'none')),
            backgroundImage: whatWeDoScrollPercentage.value.to(
              val =>
                `radial-gradient(circle at 50% ${
                  (-0.25 + (1 - val)) * 400
                }vh, rgb(252, 252, 252) 4.25758vh, rgb(252, 252, 252) 56.2879vh, rgb(116, 116, 116) 89.5455vh, rgba(0, 0, 0, 0) 119.546vh)`
            )
          }}
        >
          We partner with everyone from emerging startups to multinational brands to efficiently
          solve big (&amp; small) problems with design and code.
        </WhatWeDoText>
      </WhatWeDoInner>
      <WhatWeDoMaskPosition ref={whatWeDoRef} />
    </WhatWeDoContainer>
  );
};

export default WhatWeDo;
