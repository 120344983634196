import React from 'react';
import styled, { css } from 'styled-components';
import { animated, type SpringValue } from '@react-spring/web';

export const Letter = styled(animated.span)<{ $isElement: boolean; $isSpace: boolean }>`
  transition: opacity 0.2s, filter 2s;

  ${({ $isSpace }) =>
    $isSpace &&
    css`
      font-size: 5vw;
    `}
`;

interface FadeInTextProps {
  progress: { value: SpringValue<number> };
  children: string | React.ReactNode;
}

const FadeInText: React.FC<FadeInTextProps> = ({ progress, children }) => {
  const letters = React.Children.toArray(children)
    .map(child => {
      if (typeof child === 'string') {
        return child.split('');
      }

      return child;
    })
    .flat();

  return (
    <div>
      {letters.map((letter, index) => (
        <Letter
          key={index}
          $isElement={typeof letter !== 'string'}
          $isSpace={letter === ' '}
          style={{
            opacity: progress.value.to(val =>
              index === 0 || val >= (index + 1) / letters.length ? 1 : 0.2
            ),
            filter: progress.value.to(
              val => `grayscale(${val >= (index + 1) / letters.length ? 0 : 1})`
            )
          }}
        >
          {letter}
        </Letter>
      ))}
    </div>
  );
};

export default FadeInText;
