import styled from 'styled-components';
import { animated } from '@react-spring/web';

const CTAButton = styled(animated.button)`
  background: #fff;
  color: #000;
  border: none;
  outline: none;
  font-size: 1.125rem;
  letter-spacing: -0.075rem;
  font-weight: 700;
  border: solid #000 1px;
  border-radius: 5.875rem;
  padding: 1rem 3.5rem;
  transition: background-color 0.2s;
  font-family: SpaceGroteskLight;
  white-space: nowrap;

  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }

  &:focus {
    background: rgba(255, 255, 255, 0.7);
  }

  ${({ theme: { mq } }) => mq.md`
    padding: 1.25rem 4.25rem;
    font-size: 1.5rem;
  `}
`;

export default CTAButton;
