import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';

interface Nav {
  menu: boolean;
  landing?: boolean;
}

interface NavItem {
  $landing?: boolean;
  $active?: boolean;
}

interface Links {
  title: string;
  link: string;
}

const LINKS: Links[] = [
  {
    title: 'Work',
    link: '/projects'
  },
  {
    title: 'Team',
    link: '/team'
  },
  {
    title: 'Contact',
    link: '/contact'
  }
];

const linkArray = LINKS.map(({ link }) => link);

const Nav = styled.nav<Nav>`
  // MOBILE BASICS
  position: fixed;
  top: 0;
  left: 0;

  // BACKGROUND & ANIMATION
  background-image: url('/uploads/GFX_bg02.png');
  background-size: cover;
  opacity: ${({ menu }) => (menu ? '1' : '0')};
  visibility: ${({ menu }) => (menu ? 'visible' : 'hidden')};
  width: ${({ menu }) => (menu ? '100vw' : '0')};
  height: ${({ menu }) => (menu ? '100%' : '0')};

  transition: opacity 300ms ease-in-out, height 0ms linear ${({ menu }) => !menu && '300ms'},
    width 0ms linear ${({ menu }) => !menu && '300ms'},
    visibility 0ms linear ${({ menu }) => !menu && '300ms'};

  ${({ theme }) => theme.mq.md`
    // DESKTOP RESETS
    visibility: visible;
    opacity: 1;
    transition-property: none;
    position: relative;
    width: auto;
    height: auto;
    background-image: none;
    margin-right: 1.5rem;
  `};
`;

const NavInner = styled.div`
  display: grid;
  align-items: center;
  height: calc(100% - 71px);
  position: relative;
  top: 71px;
  overflow: scroll;

  ${({ theme }) => theme.mq.md`
    overflow: visible;
    height: 100%;
    top: 0;
  `};
`;

const Ul = styled.ul`
  padding: 0 !important;
  margin: 0 0 2rem 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  ${({ theme }) => theme.mq.md`
    margin: 0 !important;
    flex-direction: row;
    gap: 1.5rem;
  `};
`;

const Li = styled.li<NavItem>`
  list-style-type: none;
  margin: 0 !important;
`;

const StyledLink = styled(Link)<NavItem>`
  && {
    font-size: 1.5rem;
    padding: 0;
    margin: 0;
    cursor: pointer;
    text-align: center;

    ${({ $active }) =>
      $active &&
      css`
        text-decoration: underline;
      `}

    ${({ theme, $landing }) => theme.mq.md`
      display: inline-block;
      font-size: 1.125rem;

      color: ${theme.themeColors.fg};
      background-color: ${$landing ? 'rgba(0,0,0,0)' : theme.themeColors.bg};

      &:hover {
        transition: 0.3s;
        transition: 0.22s;
      }
    `};
  }
`;

const MainNav: React.FC<Nav> = ({ menu, landing }) => {
  const { asPath } = useRouter();
  return (
    <Nav menu={menu}>
      <NavInner>
        <Ul>
          {LINKS.map(({ link, title }, i) => {
            const active = linkArray.includes(link) ? asPath === link : '/projects' === link;
            return (
              <Li key={`nav-item-${i}`}>
                <StyledLink $landing={landing ? landing : false} $active={active} href={link}>
                  {title}
                </StyledLink>
              </Li>
            );
          })}
        </Ul>
      </NavInner>
    </Nav>
  );
};

export default MainNav;
