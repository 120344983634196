import React from 'react';
import styled from 'styled-components';
import { animated } from '@react-spring/web';
import { useElementScrollPercentage } from 'hooks/useElementScrollPercentage';

import FigoLogo from './svg/figo.svg';
import GoodfriendLogo from './svg/goodfriend.svg';
import IbmLogo from './svg/ibm.svg';
import KinshipLogo from './svg/kinship.svg';
import MarsLogo from './svg/mars.svg';
import MiracleGrowLogo from './svg/miracle-grow.svg';
import PlumeLogo from './svg/plume.svg';
import SpectrumHealthLogo from './svg/spectrum-health.svg';
import StarfaceLogo from './svg/starface.svg';
import SuperplasticLogo from './svg/superplastic.svg';
import ThirdEyeHealthLogo from './svg/third-eye-health.svg';
import TovalaLogo from './svg/tovala.svg';

const clients = [
  {
    name: 'Mars',
    url: 'https://www.mars.com/',
    Logo: MarsLogo,
    width: 136,
    height: 39
  },
  {
    name: 'Kinship',
    url: 'https://kinship.co/',
    Logo: KinshipLogo,
    width: 43,
    height: 58
  },
  {
    name: 'Miracle Grow',
    url: 'https://miraclegro.com',
    Logo: MiracleGrowLogo,
    width: 148,
    height: 41
  },
  {
    name: 'Figo',
    url: 'https://figopetinsurance.com/',
    Logo: FigoLogo,
    width: 112,
    height: 44
  },
  {
    name: 'Spectrum Health',
    url: 'https://www.spectrumhealth.org/',
    Logo: SpectrumHealthLogo,
    width: 170,
    height: 59
  },
  {
    name: 'IBM',
    url: 'https://www.ibm.com/',
    Logo: IbmLogo,
    width: 108,
    height: 43
  },
  {
    name: 'Tovala',
    url: 'https://www.tovala.com/',
    Logo: TovalaLogo,
    width: 174,
    height: 24
  },
  {
    name: 'Third Eye Health',
    url: 'https://thirdeyehealth.net/',
    Logo: ThirdEyeHealthLogo,
    width: 81,
    height: 65
  },
  {
    name: 'Plume',
    url: 'https://plume.com',
    Logo: PlumeLogo,
    width: 151,
    height: 39
  },
  {
    name: 'Starface',
    url: 'https://starface.world/',
    Logo: StarfaceLogo,
    width: 192,
    height: 28
  },
  {
    name: 'Superplastic',
    url: 'https://superplastic.co',
    Logo: SuperplasticLogo,
    width: 187,
    height: 25
  },
  {
    name: 'GoodFriend',
    url: 'https://goodfriend.com',
    Logo: GoodfriendLogo,
    width: 165,
    height: 25
  }
];

const Container = styled.div`
  display: grid;
  min-height: 100svh;
  align-content: center;
  justify-content: center;
  padding: 6rem 0;
  max-width: 100vw;
  overflow: hidden;

  ${({ theme: { mq } }) => mq.md`
    min-height: 80svh;
  `}
`;

const Title = styled(animated.h2)`
  text-align: center;
  font-size: 3.25rem;
  font-family: SpaceGroteskLight;
  letter-spacing: -0.13rem;
  line-height; 1.1 !important;
  font-weight: 400;
  max-width: 300px;
  margin: 0 auto;

  ${({ theme: { mq } }) => mq.md`
    font-size: 5.625rem;
    letter-spacing: -0.28125rem;
    line-height; 1.1 !important;
    max-width: unset;
  `}
`;

const ClientList = styled.ul`
  display: grid;
  list-style-type: none;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  justify-items: center;

  ${({ theme: { mq } }) => mq.sm`
    gap: 5rem;
    max-width: 600px;
  `}

  ${({ theme: { mq } }) => mq.md`
    margin-top: 4rem;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1200px;
  `}

  ${({ theme: { mq } }) => mq.lg`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

const ClientListItem = styled(animated.li)``;

const ClientLink = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  svg {
    transform: scale(0.7);
  }

  svg path,
  svg circle {
    fill: #fff;
    opacity: 0.5;
    transition: opacity 0.2s;
  }

  svg g {
    opacity: 1;
  }

  ${({ theme: { mq } }) => mq.sm`
    svg {
      transform: unset;
    }
  `}
`;

const SelectedClients: React.FC = () => {
  const { ref, scrollPercentage } = useElementScrollPercentage({ startAtTop: false });

  return (
    <Container ref={ref}>
      <Title
        style={{
          opacity: scrollPercentage.value.to([0.1, 0.4], [0, 1])
        }}
      >
        Selected Clients
      </Title>

      <ClientList>
        {clients.map((client, i) => {
          const offset = (0.3 * i) / clients.length;
          return (
            <ClientListItem
              key={client.name}
              style={{
                opacity: scrollPercentage.value.to([0.1 + offset, 0.3 + offset], [0, 1])
              }}
            >
              <ClientLink>
                <client.Logo key={client.name} width={client.width} height={client.height} />
              </ClientLink>
            </ClientListItem>
          );
        })}
      </ClientList>
    </Container>
  );
};

export default SelectedClients;
