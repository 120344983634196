import React, { useState } from 'react';
import styled, { useTheme, css } from 'styled-components';
import MainNav from 'components/MainNav';
import Icon from 'components/Icons';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { motion, AnimatePresence } from 'framer-motion';
import Link from 'next/link';

interface IHeader {
  landing?: boolean;
  teamLayout?: boolean;
}
interface IMenuContainer {
  menu: boolean;
}
interface IMenu extends IHeader, IMenuContainer {}

const Container = styled.div<IMenu>`
  // MOBILE BASICS
  width: 100vw;
  max-width: 100vw;
  position: ${({ menu, landing, teamLayout }) => {
    if (menu || landing) return 'fixed';
    else if (teamLayout) return 'sticky !important';
    return 'absolute';
  }};
  background: ${({ theme, landing }) => (landing ? 'rgba(0,0,0,0)' : theme.themeColors.bg)};
  z-index: 50;

  ${({ teamLayout, menu }) =>
    teamLayout &&
    !menu &&
    css`
      top: 0;
      z-index: 100;
    `}

  // MOBILE GRID
  display: grid;
  grid-template-rows: ${({ menu }) => (menu ? 'min-content 1fr' : 'auto')};
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'logo . nav';

  // MIMIC BORDER BOTTOM
  &:after {
    content: '';
    border-bottom: 1px solid
      ${({ theme, menu }) =>
        menu ? theme.themeColorOpacities.light30 : theme.themeColorOpacities.fg30};
    transition: border 300ms ease-in-out;
    width: 100%;
    position: absolute;
    display: block;
    top: 70px;
  }

  ${({ theme, landing, teamLayout }) => theme.mq.md`
    // DESKTOP RESETS
    position: ${landing ? 'fixed' : 'static'};
    z-index: ${teamLayout ? 1 : '99'}
    background-image: none;
    background: ${landing ? 'rgba(0,0,0,0)' : theme.themeColors.bg};
    border-bottom: 1px solid ${theme.themeColorOpacities.fg30};

    // DESKTOP GRID
    grid-template-rows: auto;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      "logo . nav";

    // RESET FALSE BORDER
    &:after {
      content: '';
      border-bottom: none;
    }
  `};
`;

const LogoContainer = styled.div<IHeader>`
  z-index: ${({ teamLayout }) => (teamLayout ? 100 : 2)};
  overflow-x: visible;
`;

const MenuContainer = styled.div<IMenu>`
  grid-area: nav;
  border-left: 1px solid
    ${({ theme, menu }) =>
      menu ? theme.themeColorOpacities.light30 : theme.themeColorOpacities.fg30};
  transition: border 300ms ease-in-out;
  padding: 0.9375rem 1rem;
  z-index: ${({ teamLayout }) => (teamLayout ? 100 : 2)};

  ${({ theme }) => theme.mq.md`
    display: none;
  `};
`;

const StyledLogo = styled(Icon)``;

const MainNavContainer = styled.div`
  grid-area: nav;
  display: flex;
  align-items: center;
`;

const Header: React.FC<IHeader> = ({ landing, teamLayout }) => {
  const [menu, setMenu] = useState(false);
  const theme = useTheme();

  // Lock app scrolling when menu is shown
  useLockBodyScroll(menu);

  const animationProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.15 }
  };

  return (
    <Container menu={menu} landing={landing ? landing : undefined} teamLayout={teamLayout}>
      <Link href="/" aria-label="Graveflex Logo">
        <LogoContainer>
          <StyledLogo name="Logo" color={menu ? '#FFF' : theme.themeColors.fg} />
        </LogoContainer>
      </Link>
      <MenuContainer menu={menu} onClick={() => setMenu(!menu)}>
        <AnimatePresence mode="wait" initial={false}>
          {menu ? (
            <motion.div key="close-icon" {...animationProps}>
              <Icon name="Close" color="#FFF" />
            </motion.div>
          ) : (
            <motion.div key="menu-icon" {...animationProps}>
              <Icon name="Menu" color={theme.themeColors.fg} />
            </motion.div>
          )}
        </AnimatePresence>
      </MenuContainer>
      <MainNavContainer onClick={() => setMenu(false)}>
        <MainNav menu={menu} landing={landing ? landing : undefined} />
      </MainNavContainer>
    </Container>
  );
};

export default Header;
