import React from 'react';
import AppContainer from 'components/landing/AppContainer';
import styled from 'styled-components';

const SAppContainer = styled(AppContainer)`
  main {
    position: unset !important;
  }
`;

interface ITeamLayout {
  children?: React.ReactElement;
}

const TeamLayout: React.FC<ITeamLayout> = ({ children }: ITeamLayout) => {
  return <SAppContainer showFooter={false}>{children}</SAppContainer>;
};

export default TeamLayout;
