import CapabilitiesLayout from 'layouts/CapabilitiesLayout';
import React from 'react';

interface CL {
  children?: React.ReactElement;
}

const CapabilityCanvasLayout: React.FC<CL> = ({ children }) => {
  return (
    <CapabilitiesLayout>
      <>
        {children}
        <div>Canvas</div>
      </>
    </CapabilitiesLayout>
  );
};

export default CapabilityCanvasLayout;
