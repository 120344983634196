import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import dynamic from 'next/dynamic';
import styled, { css } from 'styled-components';
import { useInView } from '@react-spring/web';
import { type LottieRefCurrentProps } from 'lottie-react';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

const Container = styled.div<{ $visible: boolean }>`
  opacity: 0;
  transition: opacity 1s;

  ${({ $visible }) =>
    $visible &&
    css`
      opacity: 1;
    `}
`;

interface LottiePlayerProps {
  src: string;
  loop?: boolean;
}

const LottiePlayer: React.FC<LottiePlayerProps> = ({ src, loop, ...props }) => {
  const [lottie, setLottie] = useState<string | null>(null);
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);
  const [ref, inView] = useInView({ amount: 0.2 });

  useEffect(() => {
    const fetchLottie = async () => {
      if (src) {
        const res = await axios.get(src);
        if (res?.status === 200) setLottie(res?.data);
      }
    };

    fetchLottie();
  }, [src]);

  useEffect(() => {
    if (inView) {
      lottieRef.current?.setDirection(1);
      lottieRef.current?.goToAndPlay(0);
    } else {
      lottieRef.current?.setDirection(-1);
      lottieRef.current?.play();
    }
  }, [inView]);

  return (
    <Container ref={ref} $visible={inView} {...props}>
      {lottie && <Lottie lottieRef={lottieRef} animationData={lottie} loop={!!loop} />}
    </Container>
  );
};

export default styled(LottiePlayer)``;
