import React from 'react';
import LottiePlayer from 'components/LottiePlayer';
import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';
import { useElementScrollPercentage } from 'hooks/useElementScrollPercentage';

const cards = [
  {
    heading: 'Created',
    stat: '$50M+',
    label: 'In shareholder value',
    icon: '/icons/created.svg',
    lottie: '/lotties/Impact-Lottie-1.json',
    width: 422,
    height: 397
  },

  {
    heading: 'Converted',
    stat: '3x',
    label: 'More customers',
    icon: '/icons/converted.svg',
    lottie: '/lotties/Impact-Lottie-2.json',
    width: 422,
    height: 411
  },

  {
    heading: 'Helped raise',
    stat: '$100M+',
    label: 'In funding',
    icon: '/icons/raised.svg',
    lottie: '/lotties/Impact-Lottie-3.json',
    width: 386,
    height: 386
  }
];

const ImpactListItemSvgContainer = styled.div`
  display: grid;
  align-items: start;
  justify-content: center;
  aspect-ratio: 26.375 / 25.6875;

  img {
    margin-top: 2rem;
    max-width: 100%;
    object-fit: contain;
  }
`;

const Container = styled(animated.section)<{ $debug?: boolean }>`
  display: grid;
  width: 100%;
  min-height: 80svh;
  gap: 1rem;
  padding: 6rem 1rem;
  align-content: center;
  font-family: SpaceGroteskLight;

  ${({ $debug }) =>
    $debug &&
    css`
      border: dotted red 4px;

      ${ImpactListItemSvgContainer} {
        border: dotted blanchedalmond 4px;
      }
    `}
`;

const Title = styled.h2`
  position: relative;
  font-size: 3.25rem;
  font-weight: 400;
  letter-spacing: -0.13rem;
  text-align: center;

  ${({ theme: { mq } }) => mq.md`
    font-size: 5.625rem;
  `}
`;

const ImpactList = styled.ul`
  margin: 0 auto;
  max-width: 1200px;
  list-style-type: none;
  display: grid;
  gap: 0.25rem;
  grid-template-columns: 1fr;

  ${({ theme: { mq } }) => mq.md`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

const ImpactListItem = styled(animated.li)`
  position: relative;
  background-color: #171719;
  border-radius: 2rem;
  padding: 1rem;
  margin-bottom: 0;

  ${({ theme: { mq } }) => mq.ltMd`
    transform: unset !important;
    opacity: 1 !important;
  `}

  ${({ theme: { mq } }) => mq.md`
    border-radius: 2.25rem;
  `}
`;

const Circle = styled.div`
  width: 2.24rem;
  height: 2.24rem;
  border: solid #fff 1px;
  border-radius: 50%;

  ${({ theme: { mq } }) => mq.md`
    width: 2.8125rem;
    height: 2.8125rem;
  `}
`;

const ImpactListItemHeading = styled.h5`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr max-content;
  gap: 1rem;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.2rem;

  ${({ theme: { mq } }) => mq.lg`
    font-size: 1.5rem;
  `}
`;

const ImpactListItemStat = styled.h4`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 4.49269rem;
  letter-spacing: -0.22463rem;
  line-height: 1;
  margin-top: 2rem;
  font-family: SpaceGroteskRegular;

  ${({ theme: { mq } }) => mq.md`
    font-size: 5.625rem;
    font-size: clamp(3rem, 5vw, 5.625rem);
    letter-spacing: 0;
  `}
`;

const ImpactListItemLabel = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-family: SpaceGroteskLight;

  ${({ theme: { mq } }) => mq.lg`
    font-size: 1.5rem;
  `}
`;

interface ImpactCardsProps {
  debug?: boolean;
}

const ImpactCards: React.FC<ImpactCardsProps> = ({ debug }) => {
  const { ref, scrollPercentage } = useElementScrollPercentage({ startAtTop: false });

  return (
    <Container
      $debug={debug}
      style={{
        opacity: scrollPercentage.value.to([0.1, 0.25], [0, 1])
      }}
    >
      <Title ref={ref}>Our Impact</Title>
      <ImpactList>
        {cards.map((card, i) => {
          const offset = (0.1 * i) / cards.length;

          return (
            <ImpactListItem
              key={card.heading}
              style={{
                opacity: scrollPercentage.value.to([0.1 + offset, 0.4 + offset], [0, 1]),
                y: scrollPercentage.value.to([0.1, 0.6], [100 + 200 * i, 0], 'clamp')
              }}
            >
              <ImpactListItemHeading>
                <span>{card.heading}</span> <Circle />
              </ImpactListItemHeading>
              <ImpactListItemSvgContainer>
                {!debug && <LottiePlayer src={card.lottie} />}
              </ImpactListItemSvgContainer>
              <ImpactListItemStat>{card.stat}</ImpactListItemStat>
              <ImpactListItemLabel>{card.label}</ImpactListItemLabel>
            </ImpactListItem>
          );
        })}
      </ImpactList>
    </Container>
  );
};

export default ImpactCards;
